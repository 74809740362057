// src/pages/blog/{microcmsBlog.blogId}.js
import React from "react"
import { graphql, Link } from "gatsby"
import Moment from 'react-moment';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const newsDetailPage = ({ data }) => (
  <Layout>
    <Seo title={data.microcmsPortalNews.title} />

    <section className="bg-base-main px-8 pt-16">
      <div className="max-w-5xl mx-auto">

        <div className="flex flex-wrap">
          <div className="">
            <Link to={`/`}>
              <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
            </Link>
            <span className="mx-4 text-sm">></span>
            <Link to={`/news/1`}>
              <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">イベント情報・お知らせ</button>
            </Link>
            <span className="mx-4 text-sm">></span>
            <Link to={`/news-detail/${data.microcmsPortalNews.portalNewsId}`}>
              <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">{data.microcmsPortalNews.title}</button>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-base-main px-8 py-4">
      <div className="container max-w-5xl mx-auto">

        <div className="py-6 px-8 border-2 border-base-dark bg-white rounded-lg">
          <div className="">
            <div className="py-4">
              <h1 className="font-bold text-lg">
                {data.microcmsPortalNews.title}
              </h1>
            </div>
            <div className="text-md text-base-dark w-full flex justify-between">
              <span className="bg-base-dark rounded-full font-bold text-md text-white px-4 py-1">
                <span className="text-xs">{data.microcmsPortalNews.category}</span>
              </span>
              <Moment className="text-md" format="YYYY.MM.DD">{data.microcmsPortalNews.createdAt}</Moment>
            </div>
            <div className="text-md py-8" dangerouslySetInnerHTML={{
              __html: `${data.microcmsPortalNews.body}`,
            }} />
          </div>
        </div>

      </div>
    </section>

  </Layout>
)

export default newsDetailPage

export const query = graphql`
  query($id: String!) {
    microcmsPortalNews(id: { eq: $id }) {
      category
      portalNewsId
      title
      body
      updatedAt
      createdAt
    }
    allMicrocmsPortalNews(sort: { fields: [createdAt], order: ASC }) {
      totalCount
      edges {
        node {
          body
          title
          category
          portalNewsId
          updatedAt
          createdAt
        }
      }
    }
  }
`
